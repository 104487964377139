import { PropsWithChildren } from "react";
import { Meta, Links, ScrollRestoration, Scripts, Outlet } from "react-router";
import config from "./config";
import { IntercomProvider } from "react-use-intercom";
import { Route } from "./+types/root";
import ErrorPage from "./lite/views/ErrorPage/ErrorPage";
import { PostHogProvider } from "posthog-js/react";
import LiteThemeProvider from "./lite/helpers/LiteThemeProvider";
import NeueMontreal from "./lite/views/NeueMontreal";
import GlobalStyles from "./GlobalStyles";

export function Layout({ children }: PropsWithChildren) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1.0"
        />

        <meta name="Depict" content="Depict Portal" />

        <meta
          name="shopify-api-key"
          content={import.meta.env.VITE_SHOPIFY_API_KEY}
        />
        <script src="https://cdn.shopify.com/shopifycloud/app-bridge.js"></script>

        <link rel="preconnect" href="https://rsms.me/" />
        <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />

        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/assets/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/assets/favicon/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/assets/favicon/favicon-16x16.png"
        />
        <link rel="icon" href="/assets/favicon/favicon.ico" />
        <link rel="manifest" href="/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />

        <title>Depict Portal</title>

        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

const intercomAppID = config.intercom.appId;

function CoreProviders({ children }: PropsWithChildren) {
  return (
    <LiteThemeProvider>
      <NeueMontreal />
      <GlobalStyles />
      <IntercomProvider
        appId={intercomAppID}
        autoBoot
        autoBootProps={{ hideDefaultLauncher: !import.meta.env.PROD }}
      >
        <PostHogProvider
          apiKey={config.postHog.apiKey}
          options={{
            api_host: config.postHog.host,
            session_recording: {
              maskAllInputs: false,
              maskInputOptions: {
                password: true,
              },
            },
          }}
        >
          {children}
        </PostHogProvider>
      </IntercomProvider>
    </LiteThemeProvider>
  );
}

export default function Root() {
  return (
    <CoreProviders>
      <Outlet />
    </CoreProviders>
  );
}

export function ErrorBoundary({ error }: Route.ErrorBoundaryProps) {
  return (
    <CoreProviders>
      <ErrorPage error={error} />
    </CoreProviders>
  );
}
